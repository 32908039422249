import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { usePopper } from 'react-popper'
import { motion, AnimatePresence } from 'framer-motion'
import { Link } from 'gatsby'

import Layout from '../../layout/txlss-layout'
import { SEO, Benefits } from '../../components'
import { decodeQueryString, canUseWindow } from '../../utils'

import './txtlss.scss'

import SDKsIcon from '../../assets/icons/sdks.svg'
import CloudIcon from '../../assets/icons/cloud.svg'
import TechSupportIcon from '../../assets/icons/tech-support.svg'
import OgImage from '../../assets/images/livechat-txtlss-ogimage.png'

import TxtlssHero from './TxtlssHero'

const HoverMe = ({ children, desc, setOverlay }) => {
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const [visible, setVisible] = useState(false)

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'offset', options: { offset: [0, 15] } }],
  })

  const handleClick = e => {
    e.preventDefault()
    setVisible(true)
    setOverlay(true)
  }

  const handleMouseOver = () => {
    setOverlay(true)
    setVisible(true)
  }

  const handleMouseOut = () => {
    setOverlay(false)
    setVisible(false)
  }

  return (
    <>
      <a
        href="#details"
        ref={setReferenceElement}
        onClick={handleClick}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        className={`hoverMe ${visible && 'hovered'}`}
      >
        <span className="u-text-mark">{children}</span>
      </a>

      <AnimatePresence>
        {visible && (
          <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className="popper-wrapper"
          >
            <motion.div
              animate={{
                y: 0,
                opacity: 1,
                rotateX: 0,
              }}
              initial={{
                y: 10,
                opacity: 0,
                transformPerspective: 200,
                rotateX: 4,
              }}
              exit={{
                y: -10,
                opacity: 0,
                transformPerspective: 200,
                rotateX: -4,
              }}
              transition={{ ease: 'easeOut', duration: 0.15 }}
              className={'popperBox'}
            >
              <div className="u-px-xl">{desc}</div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </>
  )
}

const Txtlss = () => {
  const queryString = (canUseWindow && window.location.search) || ''
  const { action } = decodeQueryString(queryString)
  const initialState = action === 'register'

  const [overlay, setOverlay] = useState(initialState)
  const [modal, setModal] = useState(initialState)

  const handleOpenModal = e => {
    e.preventDefault()
    setOverlay(true)
    setModal(true)
  }

  const handleCloseModal = () => {
    setOverlay(false)
    setModal(false)
  }

  return (
    <Layout
      overlay={overlay}
      setOverlay={setOverlay}
      modal={modal}
      setModal={setModal}
      handleOpenModal={handleOpenModal}
      handleCloseModal={handleCloseModal}
    >
      <SEO
        title=".txtlss – Virtual Hackathon by LiveChat Platform"
        description="Take part in virtual event aiming to disrupt the way we think about text messaging. Make new friends, win some cash and have fun!"
        image={OgImage}
      >
        <script type="text/javascript">{`(function(o){var b="https://fastfinch.co/anywhere/",t="36f01693a90246ae8d6b6dcc9c504596834bbc3b183943d6958523a8b73cf914",a=window.AutopilotAnywhere={_runQueue:[],run:function(){this._runQueue.push(arguments);}},c=encodeURIComponent,s="SCRIPT",d=document,l=d.getElementsByTagName(s)[0],p="t="+c(d.title||"")+"&u="+c(d.location.href||"")+"&r="+c(d.referrer||""),j="text/javascript",z,y;if(!window.Autopilot) window.Autopilot=a;if(o.app) p="devmode=true&"+p;z=function(src,asy){var e=d.createElement(s);e.src=src;e.type=j;e.async=asy;l.parentNode.insertBefore(e,l);};y=function(){z(b+t+'?'+p,true);};if(window.attachEvent){window.attachEvent("onload",y);}else{window.addEventListener("load",y,false);}})({});`}</script>
      </SEO>
      <section className="u-text-center u-Pb-xl txtlss-bg">
        <TxtlssHero />
        <h2 className="u-text-p3 u-mt-0 u-mb-sm">textless is not speechless</h2>
        <div className="u-text-p5 u-mt-3xl intro">
          <HoverMe
            setOverlay={setOverlay}
            desc={
              <>
                <p className="u-mb-xs">
                  <strong>Participants come from everywhere</strong>
                </p>
                <p>
                  No matter profession or interests. Engineers, designers and
                  marketers, single players and whole teams, everyone's
                  welcomed!
                </p>
              </>
            }
          >
            You
          </HoverMe>{' '}
          are invited to take part in an{' '}
          <HoverMe
            setOverlay={setOverlay}
            desc={
              <>
                <p className="u-mb-xs">
                  <strong>Participants take part remotely</strong>
                </p>
                <p>
                  Entire event is asynchronous, with occasional live meetings.
                  Don't worry about the timezones though, you won't miss a beat!
                </p>
              </>
            }
          >
            virtual hackathon
          </HoverMe>{' '}
          that aims to disrupt the way we think about text messaging.
          We&nbsp;give you{' '}
          <HoverMe
            setOverlay={setOverlay}
            desc={
              <>
                <p className="u-mb-xs">
                  <strong>Participants get support &amp; guidance</strong>
                </p>
                <p>
                  Get building blocks, libraries, data and other resources. We
                  want you to focus solely on bringing your innovation to life.
                </p>
              </>
            }
          >
            tools
          </HoverMe>
          , creative{' '}
          <HoverMe
            setOverlay={setOverlay}
            desc={
              <>
                <p className="u-mb-xs">
                  <strong>Participants join private community</strong>
                </p>
                <p>
                  Join the Slack community to meet with other innovative minds.
                  Talk to other participants, LiveChat crew and other special
                  guests.
                </p>
              </>
            }
          >
            space
          </HoverMe>{' '}
          and a{' '}
          <HoverMe
            setOverlay={setOverlay}
            desc={
              <>
                <p className="u-mb-xs">
                  <strong>Participants are having fun!</strong>
                </p>
                <p>
                  There is still a lot to discover about messaging. We want you
                  to have fun and make new friends. There are also neat $ prices
                  to win!
                </p>
              </>
            }
          >
            reason
          </HoverMe>
          .
        </div>
        <div className="u-text-p5 u-mt-2xl u-mb-3xl intro">
          {' '}
          Do&nbsp;you accept the{' '}
          <HoverMe
            setOverlay={setOverlay}
            desc={
              <>
                <p className="u-mb-xs">
                  <strong>Change the way we communicate with text</strong>
                </p>
                <p>
                  Bring new dimension to text and change how we interact with
                  messages. Let's build new messaging experiences together!
                </p>
              </>
            }
          >
            challenge
          </HoverMe>
          ?
        </div>
        <h3 className="u-text-p5 u-Mb-md u-text-center">
          This edition has come to an end.
          <br /> See you soon!
        </h3>
        {/* <a
          href={'#signup'}
          onClick={handleOpenModal}
          className="c-btn v--livechat u-text-white u-text-p4 u-py-md u-px-2xl cta-large"
        >
          Register now
        </a> */}
      </section>
      <section className="u-mb-sm u-Pt-xl u-text-center">
        <h2 className="u-text-p2 u-Mb-md u-text-center">Prizes</h2>
        <div className="o-container u-Mb-xl xs:u-Mb-2xl o-columns v--three">
          <div className="u-bg-black2 u-flex u-items-center u-flex-col u-rounded u-shadow u-justify-center u-py-xl">
            <p className="u-text-p5 u-my-0">
              1<sup>st</sup> place
            </p>
            <p className="u-text-p2 u-my-md">
              <span className="u-text-mark">$2,000</span>
            </p>
            <p className="u-text-p10 u-my-0 u-text-gray-600">
              for each teammate
            </p>
          </div>
          <div className="u-bg-black2 u-flex u-items-center u-flex-col u-rounded u-shadow u-justify-center u-py-xl">
            <p className="u-text-p5 u-my-0">
              2<sup>nd</sup> place
            </p>
            <p className="u-text-p2 u-my-md">
              <span className="u-text-mark">$1,000</span>
            </p>
            <p className="u-text-p10 u-my-0 u-text-gray-600">
              for each teammate
            </p>
          </div>
          <div className="u-bg-black2 u-flex u-items-center u-flex-col u-rounded u-shadow u-justify-center u-py-xl">
            <p className="u-text-p5 u-my-0">
              3<sup>rd</sup> place
            </p>
            <p className="u-text-p2 u-my-md">
              <span className="u-text-mark">$500</span>
            </p>
            <p className="u-text-p10 u-my-0 u-text-gray-600">
              for each teammate
            </p>
          </div>
        </div>
      </section>
      <section className="o-container u-Mb-xl o-columns v--two u-text-gray-800">
        <div className="o-container">
          <h2 className="u-text-p4">Event rules</h2>
          <ol className="u-text-p6 u-px-xl">
            <li className="u-mb-sm">
              Teams are made of 1&ndash;5 of participants. Every team can make
              only one submission and participants can join only one team.
            </li>
            <li className="u-mb-sm">
              The theme of the event is "<em>textless communication</em>". Think
              visuals, audio or emotions. Participants are welcomed to interpret
              it in their own way though!
            </li>
            <li className="u-mb-sm">
              Participants are encouraged to meet, colaborate and exchange
              opinions with each other and with organizers in the private Slack
              community till the very end of the submission period.
            </li>
            <li className="u-mb-sm">
              Participants and organizers are all there to have fun and spend
              quality time. The final outcome is as important as the entire way
              to get there!
            </li>
          </ol>
        </div>

        <div className="o-container">
          <h2 className="u-text-p4">
            <span className="u-text-mark1">Requirements</span>
          </h2>
          <ol className="u-text-p6 u-px-xl">
            <li className="u-mb-sm">
              All participants register via{' '}
              <a
                href={'#signup'}
                // onClick={handleOpenModal}
              >
                Register now
              </a>{' '}
              form to get access to the private Slack community.
            </li>
            <li className="u-mb-sm">
              Submission packages should be posted till the end of 10/25 via{' '}
              <a href="/console/" target="_blank" rel="noopener noreferrer">
                Developer Console
              </a>
              . They should include:
              <ul>
                <li>
                  {' '}
                  4-minute explanatory video presenting the concept, hosted on{' '}
                  <a
                    href="https://www.youtube.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    YouTube
                  </a>{' '}
                  or{' '}
                  <a
                    href="https://www.vimeo.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Vimeo
                  </a>
                  ,
                </li>
                <li>
                  full description of the app, concept and the team behind it.
                </li>
              </ul>
            </li>
            <li className="u-mb-sm">
              Judges will consider the following critera:
              <ul>
                <li>Impact and quality of the concept (30%)</li>
                <li>Quality of the explanatory video (30%)</li>
                <li>Relevance to the main theme (30%)</li>
                <li>
                  Implementation of{' '}
                  <a
                    href="https://www.livechat.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    LiveChat
                  </a>
                  ,{' '}
                  <a
                    href="https://www.chatbot.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ChatBot
                  </a>
                  ,{' '}
                  <a
                    href="https://www.helpdesk.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    HelpDesk
                  </a>{' '}
                  or{' '}
                  <a
                    href="https://www.knowledgebase.ai/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    KnowledgeBase
                  </a>{' '}
                  or their APIs (10%)
                </li>
              </ul>
            </li>
          </ol>
        </div>
        <div className="o-container">
          <h2 className="u-text-p4">About the host</h2>
          <p className="u-text-p6">
            Behind LiveChat, Inc. there is a team of{' '}
            <a
              href="https://www.livechat.com/team/"
              target="_blank"
              rel="noopener noreferrer"
            >
              passionate people
            </a>{' '}
            building online customer service software with online chat, help
            desk software, chatbot and web analytics capabilities.{' '}
          </p>
          <p className="u-text-p6">
            With a suite of four products (
            <a
              href="https://www.livechat.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LiveChat
            </a>
            ,{' '}
            <a
              href="https://www.chatbot.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              ChatBot
            </a>
            ,{' '}
            <a
              href="https://www.helpdesk.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              HelpDesk
            </a>
            ,{' '}
            <a
              href="https://www.knowledgebase.ai/"
              target="_blank"
              rel="noopener noreferrer"
            >
              KnowledgeBase
            </a>
            ) and their powerful APIs, we power customer communication for
            30,000 companies in 150 countries.
          </p>
          <p className="u-text-p6">
            <Link to="/">LiveChat Platform</Link> is a range of products and
            services that can be used to build a variety of communication tools
            for businesses. Our <Link to="/">Developer Program</Link> and{' '}
            <a
              href="https://www.livechat.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Marketplace{' '}
            </a>
            creates an open ecosystem for developers, partners and customers.
          </p>
        </div>
        <div className="o-container">
          <h2 className="u-text-p4">What can I build?</h2>
          <p className="u-text-p6">
            Anything! But more specifically, you can use our messaging tools to
            host audio, visual or other types of communication.
          </p>
          <p className="u-text-p6 u-mb-xs">
            You can leverage the LiveChat open messaging protocol to:
          </p>
          <ul className="u-text-p6 u-px-xl u-mt-xs">
            <li>exchange voice, images, and videos,</li>
            <li>build conversational apps &amp; games,</li>
            <li>pass data between IoT devices,</li>
            <li>program assistants and bots,</li>
            <li>create n-dimensional portals and other wild projects!</li>
          </ul>
          <p className="u-text-p6 u-mb-xs">
            If you feel you need some inspiration, let's meet and chat about it!{' '}
            <a
              href={'#signup'}
              //  onClick={handleOpenModal}
            >
              Register now
            </a>{' '}
            to join the private community. We'd be super happy to help you to
            shape up your idea!
          </p>
        </div>
      </section>

      <section className="u-bg-black2 u-Py-xl u-Mt-2xl u-Mb-md">
        <h2 className="u-text-p3 u-Mb-md u-text-center">Before you go...</h2>
        <Benefits
          content={[
            {
              icon: CloudIcon,
              label: "You don't have to be an engineer",
              description: (
                <>
                  You can use{' '}
                  <a
                    href="https://nocodelist.co/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    no-code tools
                  </a>{' '}
                  or find a teammate within our community. There are no limits,
                  everyone's invited!
                </>
              ),
            },
            {
              icon: TechSupportIcon,
              label: 'You can always count on us',
              description:
                "Our skilled support teams are ready to get you up and running. We'll also share tips and suggestions along the way!",
            },
            {
              icon: SDKsIcon,
              label: 'There are no bad ideas',
              description:
                "This event stands for innovation. Judges are ready to see all the ideas. Don't hold back your imagination!",
            },
          ]}
        />
        <div className="u-text-center">
          <h3 className="u-text-p5 u-Mb-md u-text-center">
            This edition has come to an end.
            <br /> See you soon!
          </h3>
          {/* <a
            href={'#signup'}
            onClick={handleOpenModal}
            className="c-btn v--livechat u-text-white u-text-p6-bold"
          >
            Register now
          </a> */}
        </div>
      </section>
      <section className="u-text-center u-My-xl">
        <svg
          width="110"
          height="29"
          viewBox="0 0 110 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.71 24.77C0.71 26.784 2.192 28.304 4.282 28.304C6.372 28.304 7.854 26.784 7.854 24.77C7.854 22.756 6.372 21.236 4.282 21.236C2.192 21.236 0.71 22.756 0.71 24.77ZM11.7742 2.73V8.088H9.19023V13.028H11.7742V22.11C11.7742 25.758 13.6742 28 17.4742 28H21.6922V22.832H19.6022C18.6142 22.832 18.1582 22.414 18.1582 21.502V13.028H21.9962V8.088H18.1582V2.73H11.7742ZM44.1191 8.088H37.6971L34.3151 13.104L30.9331 8.088H24.5111L31.0851 17.892L24.2451 28H30.7431L34.3151 22.68L37.8871 28H44.3851L37.5451 17.892L44.1191 8.088ZM48.1785 2.73V8.088H45.5945V13.028H48.1785V22.11C48.1785 25.758 50.0785 28 53.8785 28H58.0965V22.832H56.0065C55.0185 22.832 54.5625 22.414 54.5625 21.502V13.028H58.4005V8.088H54.5625V2.73H48.1785ZM68.4907 28V0.259999H62.1067V28H68.4907ZM90.0691 21.502C90.0691 18.766 88.3971 17.056 85.6991 16.41L79.8851 15.004C79.0111 14.814 78.6311 14.434 78.6311 13.674C78.6311 12.572 79.3911 12.04 81.0251 12.04C82.8111 12.04 83.5331 12.838 83.5331 14.054V14.396H89.0431V14.054C89.0431 10.064 85.6611 7.632 80.9491 7.632C76.1231 7.632 72.7031 10.026 72.7031 14.32C72.7031 17.132 74.2991 18.994 77.3771 19.678L82.8871 20.932C83.8751 21.16 84.2171 21.578 84.2171 22.3C84.2171 23.44 82.9251 24.048 81.2151 24.048C79.3151 24.048 78.0231 23.326 78.0231 21.92V21.654H72.3991V22.072C72.3991 26.1 75.8951 28.456 81.1771 28.456C86.4971 28.456 90.0691 25.492 90.0691 21.502ZM109.96 21.502C109.96 18.766 108.288 17.056 105.59 16.41L99.7757 15.004C98.9017 14.814 98.5217 14.434 98.5217 13.674C98.5217 12.572 99.2817 12.04 100.916 12.04C102.702 12.04 103.424 12.838 103.424 14.054V14.396H108.934V14.054C108.934 10.064 105.552 7.632 100.84 7.632C96.0137 7.632 92.5937 10.026 92.5937 14.32C92.5937 17.132 94.1897 18.994 97.2677 19.678L102.778 20.932C103.766 21.16 104.108 21.578 104.108 22.3C104.108 23.44 102.816 24.048 101.106 24.048C99.2057 24.048 97.9137 23.326 97.9137 21.92V21.654H92.2897V22.072C92.2897 26.1 95.7857 28.456 101.068 28.456C106.388 28.456 109.96 25.492 109.96 21.502Z"
            fill="#979797"
          />
        </svg>
        <p className="u-text-p8-bold u-mt-sm outro">
          Hackathon powered by <Link to="/">LiveChat Platform</Link>
        </p>
        <p className="u-text-p8 outro">
          Our vision is to help innovators build the future of the
          communication. Sounds interesting?{' '}
          <a href="https://www.livechat.com/careers/">Join us</a>!
        </p>
      </section>
    </Layout>
  )
}

HoverMe.propTypes = {
  desc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setOverlay: PropTypes.func,
}

export default Txtlss
